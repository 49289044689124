/**
 * order$1 の様な mio <-> nao の間でやり取りされている擬似的な ID を扱う
 * 値クラスなのでもうめんどいのでインターフェースはいらない
 */
export class PseudoId {
  type: string;
  value: string;

  constructor(type: string, value: string) {
    this.type = type;
    this.value = value;
  }

  toString(): string {
    return `${this.type}$${this.value}`;
  }

  equals(another: PseudoId): boolean {
    return another.type === this.type && another.value === this.value;
  }

  static buildByCombinedId(combinedId: string): PseudoId {
    const regex = /^(.+)\$(.+)$/g;
    const matches = Array.from(combinedId.matchAll(regex));
    if (matches.length < 1) {
      throw new Error(`${combinedId} is not in the format of ^(.+)\\$(.+)$`);
    }
    return new PseudoId(matches[0][1], matches[0][2]);
  }
}
