import { PseudoId } from '~/framework/domain/schedule/schedule/pseudo-entities/pseudoId';
import { hhMmToSecs, secsToHhMm } from '~/framework/services/date-time/date-time';
import { Maybe } from '~/framework/typeAliases';

const configurationStatus = { configured: false };

/**
 * String などを拡張して独自メソッドを生やして扱いやすくするためのもの
 * 基本やらない方がよさそうではあるが、あまりに便利なので一応チェッカーを入れた上でやったおく
 * TODO 拡張しない様に直す
 */
export const extendBuiltInClasses = () => {
  if (configurationStatus.configured) return;
  if ((String.prototype as any).toPseudoId) throw new Error(`String.toPseudoId is already defined!`);
  if ((String.prototype as any).toSecs) throw new Error(`String.toSecs is already defined!`);
  if ((String.prototype as any).map) throw new Error(`String.map is already defined!`);
  if ((Number.prototype as any).toHhMm) throw new Error(`Number.toHhMm is already defined!`);
  if ((Map.prototype as any).getOrError) throw new Error(`Map.getOrError is already defined!`);
  if ((Set.prototype as any).addValues) throw new Error(`Set.addValues is already defined!`);
  if ((Set.prototype as any).toArray) throw new Error(`Set.toArray is already defined!`);
  if ((Array.prototype as any).mapValues) throw new Error(`Array.mapValues is already defined!`);
  if ((Array.prototype as any).joinExceptBlank) throw new Error(`Array.joinExceptBlank is already defined!`);
  (String.prototype as any).toPseudoId = function (): PseudoId {
    return PseudoId.buildByCombinedId(this);
  };
  (String.prototype as any).toSecs = function (): number {
    return hhMmToSecs(this);
  };
  (String.prototype as any).map = function <K, V>(map: Map<K, V>): Maybe<V> {
    return map.get(this);
  };
  (Number.prototype as any).toHhMm = function (): string {
    return secsToHhMm(this);
  };
  (Map.prototype as any).getOrError = function <K, V>(key: K): V {
    const value = this.get(key);
    if (value === undefined) {
      if (typeof key === 'symbol') throw new Error(`key: ${(key as Symbol).description} was not found!`);
      else if (key instanceof Object) throw new Error(`key: ${(key as Object).toString()} was not found!`);
      else throw new Error(`key: ${key} was not found!`);
    }
    return value;
  };
  (Set.prototype as any).addValues = function <V>(...values: V[]): void {
    for (const value of values) {
      this.add(value);
    }
  };
  (Set.prototype as any).toArray = function <V>(): V[] {
    return Array.from(this);
  };
  (Array.prototype as any).mapValues = function <K, V>(map: Map<K, V>): V[] {
    return this.map((value: K) => map.getOrError(value));
  };
  (Array.prototype as string[]).joinExceptBlank = function (separator: string = ','): string {
    return this.filter((value) => value !== undefined && value !== null && value.trim().length !== 0).join(separator);
  };
  configurationStatus.configured = true;
};
